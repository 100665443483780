import resolve from '@base/utils/resolve'

interface NestedObject {
  [key: string]: any
}

/**
 * This function groups an array of objects by a specified key and stores the grouped objects under a specified group name.
 *
 * @param {Array<NestedObject>} arr - The array of objects to group.
 * @param {string} key - The key in the objects to group by. This key's value should be an object.
 * @param {string} path - The path (dot-separated) to the property in the key's value object to use for grouping.
 * @param {string} group - The name of the property in the output objects where the grouped objects should be stored.
 *
 * @returns {Array<NestedObject>} - The array of grouped objects.
 *
 * @example
 * let data = [
 *   {id: '1', title: 'show1', cinema: { id: '1', title: 'cinema1'}},
 *   {id: '13', title: 'show13', cinema: { id: '1', title: 'cinema1'}},
 *   {id: '2', title: 'show2', cinema: { id: '2', title: 'cinema2'}}
 * ];
 *
 * let result = groupObjectsByKeyInto(data, 'cinema', 'cinema.id', 'shows');
 * // result will be an array of objects grouped by cinema.id, with the grouped objects stored under the 'shows' property.
 */
export function groupObjectsByKeyInto(
  arr: Array<NestedObject>,
  key: string,
  path: string,
  group: string
): Array<NestedObject> {
  const result = arr.reduce(
    (acc: { [key: string]: NestedObject }, obj: NestedObject) => {
      const keyValue = resolve(obj, path)

      if (!acc[keyValue]) {
        acc[keyValue] = {
          [key]: resolve(obj, key),
          [group]: [],
        }
      }

      acc[keyValue][group].push(obj)

      return acc
    },
    {}
  )

  return Object.values(result)
}
