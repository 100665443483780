<template>
  <div
    class="grid-rows-auto grid w-full animate-pulse grid-cols-[auto,1fr] items-start gap-4"
  >
    <div class="w-24 md:row-span-2 md:w-40">
      <div class="relative aspect-[3/4]">
        <div :class="[cssClasses.image, cssClasses.color, 'rounded-lg']" />
      </div>
    </div>
    <div class="space-y-2 self-end md:self-start">
      <div :class="[cssClasses.color, 'h-8 w-48 rounded-lg sm:w-72']" />
      <div class="flex space-x-2 pt-1">
        <div :class="[cssClasses.color, 'h-4 w-16 rounded-lg']" />
        <div :class="[cssClasses.color, 'h-4 w-40 rounded-lg']" />
      </div>
      <div :class="[cssClasses.color, 'w-42 h-6 rounded-lg sm:w-64']" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'ProgramGroupedByMovieSkeleton',
})
</script>
